<template>
  <div class="md_content container p-3">
    <p><small>Written by {{ frontmatter.author }} on {{ dateToLocaleString(frontmatter.date) }}.</small></p>
    <p v-if="coverImage">
      CoverImage:<b-img alt="CoverImage" :src="coverImage" fluid />
    </p>
    <h2>{{ frontmatter.title }}</h2>
    <slot />
  </div>
</template>

<script>

export default {
  name: 'MarkdownView',
  props: {
    frontmatter: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    coverImage() {
      return this.frontmatter.coverimage || '';
    },
  },

  methods: {
    dateToLocaleString(inDate) {
      let strDate = '';
      const unixTime = Date.parse(inDate);
      if (unixTime) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const event = new Date(unixTime);
        strDate = event.toLocaleDateString(this.$i18n.locale, options);
      }
      return strDate;
    },
  },
};

</script>
